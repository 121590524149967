<template>
	<!--container-->
	<div class="container">
		<h2>멤버십 정보</h2>
		<div class="wrap_function">
			<span class="txt" @click="fold = !fold">
				<span class="ic_set" :class="fold ? 'ic_arrow_b' : 'ic_arrow_t'"></span>
				<a>more</a>
			</span>
		</div>
		<ul class="list_membership" v-if="fold">
			<li class="membership">
				<div class="com_box_membership">
					<h1>
						{{ originMembership.baseChannelInfoModel.baseChannelModel.name }}
					</h1>
					<span class="com_tag">
						<span class="txt">사용자 {{ originMembership.baseChannelInfoModel.totalUserCount }}인</span>
						<span class="txt"> {{ originMembership.baseChannelInfoModel.totalQuota }}GB</span>
					</span>
					<span
						class="wrap_addon_data"
						v-if="originMembership.addonChannelInfoModels && originMembership.addonChannelInfoModels.length"
					>
						<span
							class="txt"
							v-for="item in originMembership.addonChannelInfoModels"
							:key="item.addonChannelModel.code"
							>{{ item.addonChannelModel.name }}</span
						>
					</span>
				</div>
			</li>
		</ul>
		<template v-else>
			<div class="com_box_membership box_home_join" v-if="Object.keys(originMembership).length > 0">
				<h1>
					{{ originMembership.baseChannelInfoModel.baseChannelModel.name }}
				</h1>
				<div class="wrap_data_membership">
					<div class="default">
						<ul>
							<li v-if="originMembership.baseChannelInfoModel.totalUserCount > 0">
								<span class="com_tag">
									<span class="txt">
										사용자
										{{ originMembership.baseChannelInfoModel.totalUserCount }}인
									</span>
								</span>
								<span class="txt" v-if="originMembership.baseDefaultUserCnt > 0">
									기본 {{ originMembership.baseDefaultUserCnt }}명
								</span>
								<span class="txt" v-if="originMembership.baseAddUserCnt > 0">
									+ 추가 {{ originMembership.baseAddUserCnt }}명
								</span>
							</li>
							<li v-if="originMembership.baseChannelInfoModel.totalQuota > 0">
								<span class="com_tag">
									<span class="txt"> {{ originMembership.baseChannelInfoModel.totalQuota }}GB </span>
								</span>
								<span class="txt" v-if="originMembership.baseDefaultQuota > 0">
									기본 {{ originMembership.baseDefaultQuota }}GB
								</span>
								<span class="txt" v-if="originMembership.baseAddQuota > 0">
									+ 추가 {{ originMembership.baseAddQuota }}GB
								</span>
							</li>
							<li v-if="originMembership.membershipChargeType !== 'FREE'">
								<span class="com_tag">
									<span class="txt">
										<template v-if="originChargeType === 'FREE'"> 만료일 없음 </template>
										<template v-else>
											<template v-if="membershipStatus === 'USING'">
												{{ originMembership.membershipBaseInfoModel.startDate }}
												~
												{{ originMembership.membershipBaseInfoModel.expirationDate }}
											</template>
											<template v-if="membershipStatus === 'END'"> 종료 (연장 주문만 가능) </template>
											<template v-if="membershipStatus === 'IDLE'"> 초과 사용 중 (연장 주문만 가능) </template>
										</template>
									</span>
								</span>
								<span class="txt" v-if="membershipStatus === 'USING'">
									{{ getRemainDay() }}
								</span>
							</li>
						</ul>
					</div>
					<div class="divide"></div>
					<div class="option">
						<strong>옵션</strong>
						<ul v-if="originMembership.baseChannelInfoModel.usingChannelItemModels.length > 0">
							<template v-for="(item, index) in originMembership.baseChannelInfoModel.usingChannelItemModels">
								<template v-if="item.channelItemType == 'VIRTUAL_DOMAIN'">
									<li :key="'virtualDoamin_' + index" v-for="(row, index) in getVirtualDomains()">
										<span class="tit">가상도메인{{ index + 1 }}</span>
										<span class="data">{{ row }}</span>
									</li>
								</template>
								<li :key="'etc_' + index" v-if="item.channelItemType !== 'VIRTUAL_DOMAIN'">
									<span class="tit">
										{{ item.channelItemModel.displaySectionModel.displayName }}
									</span>
									<span class="data">{{ item.usingCount }}개</span>
								</li>
							</template>
						</ul>
						<span
							class="txt"
							v-if="originMembership.baseChannelInfoModel.usingChannelItemModels.length > 5"
							v-on:click="
								toggleOptionFold(
									originMembership.baseChannelInfoModel.usingChannelItemModels,
									originMembership.baseChannelInfoModel,
								)
							"
						>
							<span class="ic_set ic_arrow_b" v-if="originMembership.baseChannelInfoModel.foldFlag"></span>
							<a v-if="originMembership.baseChannelInfoModel.foldFlag">more</a>
							<span class="ic_set ic_arrow_t" v-if="!originMembership.baseChannelInfoModel.foldFlag"></span>
							<a v-if="!originMembership.baseChannelInfoModel.foldFlag">fold</a>
						</span>

						<ul v-if="originMembership.baseChannelInfoModel.usingChannelItemModels.length == 0">
							<li>
								<span class="data">사용중인 옵션 상품이 없습니다.</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</template>
		<div class="com_promotion_store promotion_2022_02_11" v-if="usePromotionAdvertisementStore()">
			<div class="tit_wrap">
				<span class="tit">{{ getPromotionAdvertisementStoreTitle() }}</span>
			</div>
			<p class="desc" v-html="getPromotionAdvertisementStoreHtml()"></p>
			<a v-on:click="promotionAdvertisementStoreLink()">
				<span class="btn_cta" v-html="getPromotionAdvertisementStoreButton()"></span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			promotionAdvertisementStore: {
				use: false,
			},
		};
	},
	props: ['fold', 'originMembership', 'remainDay', 'membershipStatus', 'originChargeType'],
	watch: {
		fold() {
			this.$emit('changeFold', this.fold);
		},
	},
	created() {
		this.promotionAdvertisementStore = this.$store.getters.getPromotionAdvertisementStore;
	},
	methods: {
		getVirtualDomains() {
			if (this.originMembership.representativeGroupware) {
				return this.originMembership.representativeGroupware.virtualDomain;
			} else {
				return [];
			}
		},
		getRemainDay() {
			const remainDay = this.remainDay;
			const strRemainDay =
				(remainDay.remainMonth > 0 ? remainDay.remainMonth + '개월' : '') +
				(remainDay.remainMonth > 0 && remainDay.remainDay > 0 ? ' ' : '') +
				(remainDay.remainDay > 0 ? remainDay.remainDay + '일' : '');
			return strRemainDay;
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
		},
		usePromotionAdvertisementStore() {
			return (
				this.promotionAdvertisementStore.use &&
				this.promotionAdvertisementStore.chargeType.includes(this.originChargeType)
			);
		},
		getPromotionAdvertisementStoreTitle() {
			return this.promotionAdvertisementStore.title;
		},
		getPromotionAdvertisementStoreHtml() {
			return this.promotionAdvertisementStore.html;
		},
		getPromotionAdvertisementStoreButton() {
			return this.promotionAdvertisementStore.button;
		},
		promotionAdvertisementStoreLink() {
			window.open(this.promotionAdvertisementStore.link, '_blank');
		},
	},
	filters: {
		parseDate(value) {
			const date = new Date(value);
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
	},
};
</script>

<style></style>
