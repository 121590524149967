<template>
	<div class="wrap_row_card">
		<span class="tit">가상 도메인</span>
		<span v-if="item.displaySectionModel.useTooltip" class="com_help">
			<span class="ic_set ic_info">
				<span class="tool_tip">
					{{ item.displaySectionModel.tooltipDesc }}
					<i class="tail_left"></i
				></span>
			</span>
		</span>
		<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
			<span class="txt">{{
				item.displaySectionModel.iconType === 'HOT'
					? 'HOT'
					: item.displaySectionModel.iconType === 'RECOMMENDATION'
					? '추천'
					: ''
			}}</span>
		</span>
		<span class="com_tag tag_s" v-if="item.usingCount">
			<span class="txt">사용중</span>
		</span>
		<div class="wrap_meta_ctrl">
			<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
				<span class="txt">견적가</span>
			</template>
			<template v-else>
				<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
					>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
						item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
					}}개</span
				>
				<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
					>{{ item.channelItemSalesSectionModel.price | currency }}원</span
				>
			</template>

			<span class="price billing_type">
				{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
			</span>
			<span class="com_number" v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
				<button
					class="minus"
					v-on:click="
						openAlert(
							'가상 도메인 수량 취소',
							'가상 도메인 수량 취소는 삭제하고자 하는 도메인 옆 X 버튼을 이용해주세요.',
						)
					"
				>
					<span class="ic_set ic_minus"></span>
				</button>
				<span class="txt">
					{{ item.orderCount }}
				</span>
				<button
					class="plus"
					@click="
						addDomainText(item);
						upCntOptionItem(item, optionPerService.service);
					"
				>
					<span class="ic_set ic_plus"></span>
				</button>
			</span>
			<span :class="[item.gapCount !== 0 ? 'txt modified' : '']">
				<template v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
					<template v-if="item.gapCount > 0"> 기존 {{ item.usingCount }} + 추가 {{ item.gapCount }}</template
					><template v-if="item.gapCount < 0"> 기존 {{ item.usingCount }} - 취소 {{ -1 * item.gapCount }}</template>
				</template>
			</span>
		</div>
		<div class="wrap_sub">
			<table class="com_tb_metadata tb_sub_option">
				<tbody>
					<tr v-for="index in item.orderCount" :key="optionPerService.service.code + item.itemCode + index">
						<td class="tit">
							<span class="ic_set ic_depth"> </span>
							<span class="txt"> 가상 도메인 {{ index }} </span>
						</td>
						<td class="data">
							<div class="com_ipt outlined">
								<input
									:disabled="index <= item.usingCount"
									class="ipt_normal"
									type="text"
									value="sample"
									v-model="item.domainText[index - 1].text"
									placeholder="sample.com"
								/>
							</div>
							<button
								class="del"
								title="삭제"
								v-on:click="
									checkDownCntOptionItem(
										item,
										optionPerService.service,
										'VIRTUAL_DOMAIN',
										index,
										item.domainText[index - 1].isExisting,
									)
								"
							>
								<i class="ic_set ic_del"></i>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
export default {
	mixins: [option],
	props: ['optionPerService', 'item'],
	methods: {
		addDomainText(item) {
			if (item.orderCount >= item.domainText.length) {
				item.domainText.push({ text: '', isExisting: false });
			}
		},
	},
};
</script>
<style scoped>
.billing_type {
	margin-left: -10px;
}
</style>
