var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap_row_card"},[_c('span',{staticClass:"tit"},[_vm._v("가상 도메인")]),(_vm.item.displaySectionModel.useTooltip)?_c('span',{staticClass:"com_help"},[_c('span',{staticClass:"ic_set ic_info"},[_c('span',{staticClass:"tool_tip"},[_vm._v(" "+_vm._s(_vm.item.displaySectionModel.tooltipDesc)+" "),_c('i',{staticClass:"tail_left"})])])]):_vm._e(),(_vm.item.displaySectionModel.useIcon)?_c('span',{staticClass:"com_tag_badge"},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(_vm.item.displaySectionModel.iconType === 'HOT' ? 'HOT' : _vm.item.displaySectionModel.iconType === 'RECOMMENDATION' ? '추천' : ''))])]):_vm._e(),(_vm.item.usingCount)?_c('span',{staticClass:"com_tag tag_s"},[_c('span',{staticClass:"txt"},[_vm._v("사용중")])]):_vm._e(),_c('div',{staticClass:"wrap_meta_ctrl"},[(_vm.item.channelItemSalesSectionModel.priceType === 'ESTIMATE')?[_c('span',{staticClass:"txt"},[_vm._v("견적가")])]:[(_vm.item.channelItemSalesSectionModel.billingType == 'MONTHLY')?_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("currency")(_vm.item.channelItemSalesSectionModel.price))+"원 /"+_vm._s(_vm.item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit)+"개")]):_vm._e(),(_vm.item.channelItemSalesSectionModel.billingType == 'ONCE')?_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm._f("currency")(_vm.item.channelItemSalesSectionModel.price))+"원")]):_vm._e()],_c('span',{staticClass:"price billing_type"},[_vm._v(" "+_vm._s(_vm._f("billingTypeDesc")(_vm.item.channelItemSalesSectionModel.billingType))+" ")]),(_vm.item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity)?_c('span',{staticClass:"com_number"},[_c('button',{staticClass:"minus",on:{"click":function($event){return _vm.openAlert(
						'가상 도메인 수량 취소',
						'가상 도메인 수량 취소는 삭제하고자 하는 도메인 옆 X 버튼을 이용해주세요.',
					)}}},[_c('span',{staticClass:"ic_set ic_minus"})]),_c('span',{staticClass:"txt"},[_vm._v(" "+_vm._s(_vm.item.orderCount)+" ")]),_c('button',{staticClass:"plus",on:{"click":function($event){_vm.addDomainText(_vm.item);
					_vm.upCntOptionItem(_vm.item, _vm.optionPerService.service);}}},[_c('span',{staticClass:"ic_set ic_plus"})])]):_vm._e(),_c('span',{class:[_vm.item.gapCount !== 0 ? 'txt modified' : '']},[(_vm.item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity)?[(_vm.item.gapCount > 0)?[_vm._v(" 기존 "+_vm._s(_vm.item.usingCount)+" + 추가 "+_vm._s(_vm.item.gapCount))]:_vm._e(),(_vm.item.gapCount < 0)?[_vm._v(" 기존 "+_vm._s(_vm.item.usingCount)+" - 취소 "+_vm._s(-1 * _vm.item.gapCount))]:_vm._e()]:_vm._e()],2)],2),_c('div',{staticClass:"wrap_sub"},[_c('table',{staticClass:"com_tb_metadata tb_sub_option"},[_c('tbody',_vm._l((_vm.item.orderCount),function(index){return _c('tr',{key:_vm.optionPerService.service.code + _vm.item.itemCode + index},[_c('td',{staticClass:"tit"},[_c('span',{staticClass:"ic_set ic_depth"}),_c('span',{staticClass:"txt"},[_vm._v(" 가상 도메인 "+_vm._s(index)+" ")])]),_c('td',{staticClass:"data"},[_c('div',{staticClass:"com_ipt outlined"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.domainText[index - 1].text),expression:"item.domainText[index - 1].text"}],staticClass:"ipt_normal",attrs:{"disabled":index <= _vm.item.usingCount,"type":"text","value":"sample","placeholder":"sample.com"},domProps:{"value":(_vm.item.domainText[index - 1].text)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item.domainText[index - 1], "text", $event.target.value)}}})]),_c('button',{staticClass:"del",attrs:{"title":"삭제"},on:{"click":function($event){return _vm.checkDownCntOptionItem(
									_vm.item,
									_vm.optionPerService.service,
									'VIRTUAL_DOMAIN',
									index,
									_vm.item.domainText[index - 1].isExisting,
								)}}},[_c('i',{staticClass:"ic_set ic_del"})])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }