<template>
	<div class="wrap_row_card">
		<div class="com_ck bk" v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
			<label
				><input
					type="checkbox"
					:disabled="isItemDisable(item)"
					:checked="isItemChecked(item)"
					v-on:click="
						item.orderCount == 0
							? upCntOptionItem(item, optionPerService.service)
							: checkDownCntOptionItem(item, optionPerService.service)
					" /><span class="checkmark"></span
			></label>
		</div>

		<span class="tit">{{ item.displaySectionModel.displayName }}</span>
		<span v-if="item.displaySectionModel.useTooltip" class="com_help">
			<span class="ic_set ic_info">
				<span class="tool_tip">
					{{ item.displaySectionModel.tooltipDesc }}
					<i class="tail_left"></i
				></span>
			</span>
		</span>
		<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
			<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
			<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt">추천</span>
		</span>
		<span class="com_tag tag_s" v-if="item.usingCount > 0">
			<span class="txt">사용중</span>
		</span>
		<div class="wrap_meta_ctrl" v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
			<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
				<span class="price">견적가</span>
			</template>
			<template v-else>
				<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
					>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
						item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
					}}개</span
				>
				<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
					>{{ item.channelItemSalesSectionModel.price | currency }}원</span
				>
			</template>
			<span class="price billing_type">
				{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
			</span>

			<span class="com_number">
				<button class="minus" v-on:click="checkDownCntOptionItem(item, optionPerService.service)">
					<span class="ic_set ic_minus"></span>
				</button>
				<span class="txt">{{ item.orderCount }}</span>
				<button class="plus" v-on:click="upCntOptionItem(item, optionPerService.service)">
					<span class="ic_set ic_plus"></span>
				</button>
			</span>
		</div>
		<div class="wrap_meta_data" v-else>
			<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
				<span class="txt">견적가</span>
			</template>
			<template v-else>
				<span class="txt" v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'">
					{{ item.channelItemSalesSectionModel.price | currency }}원 /{{ unitCount(item) }}개</span
				>
				<span class="txt" v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'"
					>{{ item.channelItemSalesSectionModel.price | currency }}원
				</span>
			</template>
			<span class="txt">
				{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
			</span>
		</div>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
export default {
	mixins: [option],
	props: ['item', 'optionPerService', 'totUserCnt', 'unitCount'],
};
</script>

<style scoped>
.billing_type {
	margin-left: -10px;
}
</style>
