<template>
	<div
		class="container"
		v-if="usingMembership.addonChannelInfoModels && usingMembership.addonChannelInfoModels.length"
		style="margin-top: -16px"
	>
		<h2>부가서비스</h2>
		<div class="com_card_normal card_addon" v-if="!usingMembership.addonChannelInfoModels.length">
			<div class="null_data">
				<span class="txt">구매한 부가서비스 정보가 없습니다.</span>
				<!--<button
					class="mono major"
					v-on:click="confirmPurchaseAddon('perchase')"
					v-if="usingMembership.partnerSimpleModel.salesType !== 'RESELLER'"
				>
					<span class="txt">구매</span>
				</button>-->
			</div>
		</div>
		<div
			class="com_card_normal card_addon card_addon_home"
			v-for="(item, index) in usingMembership.addonChannelInfoModels"
			:key="item.addonChannelModel.code + index"
		>
			<div class="wrap_row_card">
				<span
					v-if="addonChannelIconClass(item.addonChannelModel.name)"
					class="ic_svc"
					:class="addonChannelIconClass(item.addonChannelModel.name)"
				></span>
				<span class="tit">{{ item.addonChannelModel.name }}</span>
				<span class="txt sub">부가서비스</span>
				<span
					v-if="item.addonChannelModel.freeTrialPolicyModel.usePolicy && item.freeTrialStatus !== 'END'"
					class="com_tag"
				>
					<span class="txt">무료체험 사용중</span>
				</span>
				<!-- 4월 이후 개발
					<button
						class="mono major"
						v-if="
							item.addonChannelModel.freeTrialPolicyModel.usePolicy &&
								item.freeTrialStatus == 'USING'
						"
						v-on:click="ConvertingPaid(item)"
					>
						<span class="txt">유료전환</span>
					</button> -->
			</div>
			<div class="wrap_data_membership">
				<div class="default">
					<ul>
						<li>
							<span class="com_tag">
								<span class="txt">사용자 {{ item.totalUserCount }}인</span>
							</span>
							<span class="txt">
								<template v-if="item.addonChannelModel.defaultFeePolicyModel.defaultUserNum > 0">
									기본
									{{ item.addonChannelModel.defaultFeePolicyModel.defaultUserNum }}
									명
								</template>
							</span>
						</li>
						<li v-if="usingMembership.membershipChargeType !== 'FREE'">
							<span class="com_tag">
								<span class="txt">
									{{ today }}~
									{{ getFormateDate(usingMembership.membershipBaseInfoModel.expirationDate) }}
								</span>
							</span>
						</li>
					</ul>
				</div>
				<div class="divide"><div class="line"></div></div>
				<div class="option">
					<strong>옵션</strong>
					<ul v-if="item.usingChannelItemModels.length">
						<template v-for="(data, index) in sortDefault(item.usingChannelItemModels)">
							<li :key="data.channelItemModel.code + 'etc' + index" v-if="data.channelItemType !== 'VIRTUAL_DOMAIN'">
								<span class="tit">
									<span
										v-if="addonChannelIconClass(data.channelItemModel.name, true)"
										class="ic_svc"
										:class="addonChannelIconClass(data.channelItemModel.name, true)"
									></span>
									{{ data.channelItemModel.name }}</span
								>
								<span class="data">{{ data.usingCount }}개</span>
							</li>
						</template>
						<template v-if="item.prepaidInfoCollection.prepaidInfos.length > 0">
							<li>
								<span class="tit">전자세금계산서</span>
								<span class="data">사용중</span>
							</li>
						</template>
					</ul>
					<span
						v-if="item.usingChannelItemModels.length > 5"
						class="txt"
						v-on:click="toggleOptionFold(item.usingChannelItemModels, item)"
					>
						<span class="ic_set ic_arrow_b" v-if="item.foldFlag"></span>
						<a v-if="item.foldFlag">more</a>
						<span class="ic_set ic_arrow_t" v-if="!item.foldFlag"></span>
						<a v-if="!item.foldFlag">fold</a>
					</span>
					<ul v-if="item.usingChannelItemModels.length == 0">
						<span class="tit">사용중인 옵션 상품이 없습니다.</span>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
export default {
	data() {
		return {
			today: this.$moment().format('YYYY-MM-DD'),
		};
	},
	props: {
		usingMembership: Object,
	},
	methods: {
		addonChannelIconClass(addonChannelName, isOption) {
			if (addonChannelName.indexOf(this.$constants.ACCOUNTING) > -1) {
				return isOption ? 'ic_accounting_s' : 'ic_accounting';
			} else if (addonChannelName === this.$constants.BIZPLAY) {
				return 'ic_bizplay';
			} else if (addonChannelName === this.$constants.TMSE_S) {
				return 'ic_tmse_s';
			} else if (addonChannelName === this.$constants.TMSE_E) {
				return 'ic_tmse_e';
			} else if (addonChannelName === this.$constants.TMSE_SHARE) {
				return 'ic_tmse_share';
			}
			return undefined;
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
		},
		getFormateDate(val) {
			const date = new Date(val);
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
		sortDefault(usingChannelItemModels) {
			const cloneItemModels = cloneDeep(usingChannelItemModels);
			let sortItem = cloneItemModels.sort((a, b) => {
				if (a.itemName == '경영지원' && a.channelItemType == 'DEFAULT') {
					return -1;
				} else {
					return a.id - b.id;
				}
			});
			return sortItem;
		},
	},
};
</script>

<style></style>
