<template>
	<div class="container" v-if="visibleAddonServices.length && isDomsAdmin()">
		<h2>부가 서비스</h2>
		<div class="form_data">
			<template v-for="(item, index) in visibleAddonServices">
				<div
					class="com_card_normal card_addon"
					:class="{
						active: item.isSelected,
					}"
					@click="canReduceItem(item, extensionPeriod)"
					:key="index"
					v-if="item.visible"
				>
					<div class="com_ck bk">
						<label
							><input
								:disabled="isDisabledAddon(item)"
								type="checkbox"
								v-on:click="selectAddonService(item)"
								:checked="item.isSelected" /><span class="checkmark"></span
						></label>
					</div>
					<div class="wrap_row_card">
						<span v-if="getItemClass(item)" class="ic_svc" :class="getItemClass(item)"></span>
						<span class="tit">{{ item.name }}</span>
						<span v-if="item.freeTrialPolicyModel.usePolicy == true" class="com_tag_badge">
							<span class="txt" v-if="item.usingCount == 0"
								>{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료 체험</span
							>
							<span class="txt" v-if="item.usingCount > 0">유료 전환 완료</span>
						</span>
						<span class="com_tag tag_s" v-if="item.usingCount > 0">
							<span class="txt">사용중</span>
						</span>
					</div>
					<div class="wrap_meta_addon">
						<table class="com_tb_metadata">
							<tbody>
								<tr>
									<td class="tit"><span class="txt">기본료</span></td>
									<td class="data">
										<span class="txt"
											>{{ item.defaultFeePolicyModel.price | currency }}원
											{{ item.defaultFeePolicyModel.billingType | billingTypeDesc }}</span
										>
									</td>
								</tr>
								<tr>
									<td class="tit"><span class="txt">설치비</span></td>
									<td class="data">
										<span class="txt"
											>{{ item.membershipFeePolicyModel.price | currency }}원
											{{ item.membershipFeePolicyModel.billingType | billingTypeDesc }}</span
										>
									</td>
								</tr>
								<tr>
									<td class="tit">
										<span class="txt">사용자 (1명)</span>
									</td>
									<td class="data">
										<span class="txt"
											>{{ item.addUserIntervalPolicyModel.price | currency }}원
											{{ item.addUserIntervalPolicyModel.billingType | billingTypeDesc }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { productClassifier } from '@/views/mixinProductClassifier.js';
import { countValidate } from '@/components/apply/user/store/addExtension/mixinsCountValidate.js';
export default {
	props: ['value', 'extensionPeriod'],
	mixins: [productClassifier, countValidate],
	data() {
		return {
			addonServices: this.value,
		};
	},
	computed: {
		visibleAddonServices() {
			return this.addonServices.filter((obj) => obj.visible);
		},
	},
	watch: {
		value() {
			this.addonServices = this.value;
		},
		addonServices: {
			deep: true,
			handler() {
				this.$emit('input', this.addonServices);
				this.checkRequiredAddonChannel();
			},
		},
	},
	methods: {
		// 연장,추가시에도 공유형 근태연차는 필수이므로 사용중이 아니더라도 기본으로 선택되도록 한다.
		checkRequiredAddonChannel() {
			const visibleAddonServices = this.visibleAddonServices;
			const requireSelectedAddonChannel = visibleAddonServices.filter(
				(addon) => this.isSaasAttendance(addon) && addon.usingCount === 0 && !addon.isSelected,
			);
			requireSelectedAddonChannel.forEach((addon) => {
				this.selectAddonService(addon);
			});
		},
		selectAddonService(item) {
			this.$emit('selectAddonChannel', item);
		},
		getItemClass(item) {
			let itemClass = '';
			if (this.isAccountAddonChannel(item)) {
				itemClass = 'ic_accounting';
			} else {
				switch (item.name) {
					case '경비처리(무증빙)':
						itemClass = 'ic_bizplay';
						break;
					case 'TMSe 장비일체형 S':
						itemClass = 'ic_tmse_s';
						break;
					case 'TMSe 장비일체형 E':
						itemClass = 'ic_tmse_e';
						break;
					case 'TMSe 장비일체형 T':
						itemClass = 'ic_tmse_share';
						break;
				}
			}
			return itemClass;
		},
	},
	filters: {
		billingTypeDesc(value) {
			let billingTypeDesc = '';
			switch (value) {
				case 'ONCE':
					billingTypeDesc = '(1회)';
					break;
				case 'MONTHLY':
					billingTypeDesc = '/월';
					break;
				case 'ESTIMATE':
					billingTypeDesc = '(견적가)';
					break;
			}
			return billingTypeDesc;
		},
	},
};
</script>

<style></style>
