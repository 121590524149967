<template>
	<div class="container" v-if="Object.keys(usingMembership).length > 0 && !onlyChange && currentChargeType !== 'FREE'">
		<h2>연장 기간</h2>
		<div class="form_data">
			<div class="com_radio">
				<form>
					<p v-if="!onlyExtension">
						<template v-if="currentChargeType === 'FREE'">
							<input
								type="radio"
								:id="0"
								v-model="extensionPeriod"
								:value="0"
								:disabled="membershipStatus !== 'USING'"
							/>
							<label :for="0"
								>선택 안함
								<span class="txt_sub">옵션상품 추가 없이 기존 무료멤버십 그대로 사용</span>
							</label>
						</template>
						<template v-else>
							<input
								type="radio"
								:id="0"
								v-model="extensionPeriod"
								:value="0"
								:disabled="membershipStatus !== 'USING'"
							/>
							<template>
								<label :for="0"
									>연장 안함
									<span class="txt_sub">연장 없이 상품만 추가</span>
								</label>
							</template>
						</template>
					</p>
					<template
						v-if="!usingMembership.autoExtension || (usingMembership.autoExtension && membershipStatus !== 'USING')"
					>
						<p v-for="row in periodDisplay" :key="row.value">
							<input type="radio" :id="row.value" v-model="extensionPeriod" :value="row.value" />
							<label :for="row.value"
								>{{ row.display }}
								<span class="txt_sub">{{ row.displaySub }}</span>
							</label>
						</p>
					</template>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { isEmpty } from '@/utils/utils';

export default {
	props: ['value', 'usingMembership', 'membershipStatus', 'originChargeType', 'currentChargeType'],
	data() {
		return {
			extensionPeriod: this.value,
			cannotExtendOrder: false,
		};
	},
	computed: {
		onlyExtension() {
			const onlyExtension =
				(this.originChargeType === 'FREE' && this.currentChargeType === 'FREE_MONTHLY') |
				(this.originChargeType === 'FREE' && this.currentChargeType === 'PAY')
					? true
					: false;
			return onlyExtension;
		},
		onlyChange() {
			const onlyChange = this.originChargeType === 'FREE_MONTHLY' && this.currentChargeType === 'PAY';
			return onlyChange;
		},
		periodDisplay() {
			return this.setPeriodDisplay(
				this.usingMembership.baseChannelInfoModel.baseChannelModel.periodPolicyModel,
				this.usingMembership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType,
			);
		},
	},
	watch: {
		currentChargeType() {
			if (isEmpty(this.originChargeType)) {
				return;
			}
			if (this.originChargeType === 'FREE_MONTHLY' && this.currentChargeType === 'FREE') {
				// 무료월과금 -> 무료 일반 연장 시
				this.extensionPeriod = 12;
			} else if (
				(this.originChargeType !== 'FREE_MONTHLY' && this.currentChargeType === 'FREE_MONTHLY') ||
				(this.originChargeType === 'FREE' && this.currentChargeType === 'PAY')
			) {
				this.extensionPeriod = 1;
			} else if (this.currentChargeType === 'FREE') {
				this.extensionPeriod = 0;
			} else if (this.originChargeType === 'FREE_MONTHLY' && this.currentChargeType === 'PAY') {
				this.extensionPeriod = 0;
			}
		},
		value() {
			this.extensionPeriod = this.value;
		},
		extensionPeriod() {
			this.$emit('input', this.extensionPeriod);
		},
	},
	methods: {
		// API response 데이터를 사용기간 디스플레이 format으로 setting
		setPeriodDisplay(periodPolicyModel, serviceType) {
			let result = [];

			if (this.currentChargeType !== 'FREE') {
				// 신규 신청 시, 사용 기간
				let newOrderUsingTerms;
				if (serviceType == 'SAAS') {
					newOrderUsingTerms = [1, 12];
				} else {
					newOrderUsingTerms = [12];
				}
				// 장기선납 할인 中 일 경우
				if (periodPolicyModel.useLongTermPrepaymentDiscount) {
					let discountRate = periodPolicyModel.discountRate;

					for (let i in newOrderUsingTerms) {
						let ii = discountRate.findIndex((obj) => obj.month == newOrderUsingTerms[i]);
						// 장기선납 문구 추가
						if (ii > -1) {
							result.push({
								value: parseInt(newOrderUsingTerms[i]),
								display: newOrderUsingTerms[i].toString().concat('개월'),
								displaySub: '장기선납 ' + discountRate[ii].rate + '%할인',
							});
						} else if (newOrderUsingTerms[i] == 1) {
							result.push({
								value: parseInt(newOrderUsingTerms[i]),
								display: newOrderUsingTerms[i].toString().concat('개월 자동결제'),
								displaySub: '　',
							});
						} else {
							result.push({
								value: parseInt(newOrderUsingTerms[i]),
								display: newOrderUsingTerms[i].toString().concat('개월'),
								displaySub: '　',
							});
						}
					}
				} else {
					for (let idx in newOrderUsingTerms) {
						if (newOrderUsingTerms[idx] == 1) {
							result.push({
								value: parseInt(newOrderUsingTerms[idx]),
								display: newOrderUsingTerms[idx].toString().concat('개월 자동결제'),
							});
						} else {
							result.push({
								value: parseInt(newOrderUsingTerms[idx]),
								display: newOrderUsingTerms[idx].toString().concat('개월'),
							});
						}
					}
				}
			}

			return result;
		},
	},
};
</script>

<style></style>
