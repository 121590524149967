import { productClassifier } from '@/views/mixinProductClassifier';
export const countValidate = {
	mixins: [productClassifier],
	methods: {
		openAlert(title, content) {
			this.$ms.dialog.alert(title, content);
		},
		isDomsAdmin() {
			return this.$utils.isDomsAdmin();
		},
		isDisabledAddon(item) {
			// 현재 사용중인 부가서비스 중에서
			if (item.usingCount > 0) {
				// 현재 화면에 설정된 연장기간이 0 이거나, 혹은 필수 부가서비스인 경우
				if (this.extensionPeriod === 0 || this.isRequiredAddonChannel(item)) {
					return true;
				}
			}
			// 공유형 신근태연차를 사용중일 경우에 해제가 불가능하도록 막는다.
			else if (item.isSelected && this.isSaasAttendance(item)) {
				return true;
			}
			return false;
		},
		canReduceItem(item, extensionPeriod) {
			const usingCount = item.usingCount;
			if (usingCount > 0 && extensionPeriod === 0) {
				this.openAlert('구매 취소가 불가능합니다.', '현재 사용중인 수 부가서비스는 구매 취소가 불가합니다.');
				return;
			}
		},
	},
};
