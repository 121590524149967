import { order } from '@/components/apply/mixinsOrder';

export const calculate = {
	mixins: [order],
	methods: {
		// 설치비(도입/설치비) 원가, 할인가 setting -- 1회성
		/* item : channelModel
		 * type : add(신규 추가)
		 * 무료에서 유료로 전환할 때 이 메소드가 호출 됨.
		 */
		async setMembershipFeeDiscountMatrix(item) {
			// TODO 공통화
			const itemCount = 1; // 설치비는 개수가 하나
			const channelItemType = 'MEMBERSHIP_FEE';
			const date = this.$store.getters.getDates;
			let orderInfoModel = {
				membershipModel: {
					id: this.$store.getters.getUsingMembership.id,
				},
				partnerSimpleModel: {
					partnerId: this.$store.getters.getUsingMembership.partnerSimpleModel.partnerId,
				},
				baseInfoModel: {
					orderType: 'ADD',
				},
				serviceType: item.serviceCategoryModel.serviceType,
				baseChannelOrderInfoModel: {
					applyDate: date.addStartDate,
					endDate: date.addEndDate,
					baseChannelModel: {
						code: item.code, // 서비스 아이템 코드
						id: item.id, // 서비스 id
					},
					membershipFeeOrderDetail: {
						channelItemSimpleModel: {
							id: item.id,
						},
						existingCount: 0,
						addCount: itemCount,
						billingType: 'ONCE',
						channelItemType: channelItemType,
						itemCode: item.code,
						price: item.membershipFeePolicyModel.price,
					},
				},
			};

			const type = this.$store.getters.getFixedOrderType;
			if (type === 'USERADD' || type === 'ADD') {
				orderInfoModel.baseInfoModel.orderType = 'ADD';
				orderInfoModel.baseChannelOrderInfoModel.applyDate = date.addStartDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.addEndDate;
			} else if (type === 'EXTENSION') {
				orderInfoModel.baseInfoModel.orderType = 'EXTENSION';
				orderInfoModel.baseChannelOrderInfoModel.applyDate = date.extensionStartDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.extensionEndDate;
				orderInfoModel.baseChannelOrderInfoModel.membershipFeeOrderDetail.addCount = 0;
				orderInfoModel.baseChannelOrderInfoModel.membershipFeeOrderDetail.existingCount = 1;
			}

			const itemCalculateRequestModel = {
				orderInfoModel: orderInfoModel,
				channelItemType: channelItemType,
			};

			// TODO
			const response = await this.$store.dispatch('CALCULATE_ITEM_PRICE', itemCalculateRequestModel);
			const priceModel = response.data.priceModel;
			// 정가
			item['membershipFeeNormalPrice'] = priceModel.totalOriginPrice;
			// 할인된 적용된 금액
			item['membershipFeeDiscountPrice'] = priceModel.totalFinalPrice;
			// 할인 금액
			item['membershipFeeDiscount'] = priceModel.totalDiscountPrice;

			return item;
		},
		// 기본료 원가, 할인가 setting
		/* item : channelModel
		 * userCnt: 사용자 수
		 * usePeriod : 사용기간
		 *             - 연장 : 개월 (usePeriod)
		 * 			   - 추가 : 개월 + 일 (usePeriod.remainMonth, usePeriod.remainDay)
		 * type : extension(연장) / add(신규 추가)
		 */
		async setDefaultFeeDiscountMatrix(item, usePeriod, type) {
			const channelItemType = 'DEFAULT_FEE';
			// TODO 공통화
			let orderInfoModel = {
				membershipModel: {
					id: this.$store.getters.getUsingMembership.id,
				},
				partnerSimpleModel: {
					partnerId: this.$store.getters.getUsingMembership.partnerSimpleModel.partnerId,
				},
				baseInfoModel: {
					orderType: '',
				},
				serviceType: item.serviceCategoryModel.serviceType,
				baseChannelOrderInfoModel: {
					applyDate: '',
					endDate: '',
					baseChannelModel: {
						code: item.code, // 서비스 아이템 코드
						id: item.id, // 서비스 id
					},
					defaultFeeOrderDetail: {
						channelItemSimpleModel: {
							id: item.id,
						},
						/**
						 * 기본 서비스 수량은, 연장 사용 개수가 있으면 추가 사용 개수는 0이어야 함.
						 * 만약 공유형 기본 서비스가 존재하는 어떤 고객사가 메일보안이라는 부가서비스 추가 연장을 하려 한다면
						 * 공유형 연장 금액 계산할 때 이 메소드를 호출하고,
						 * 메일보안 연장 금액 계산할 때 또 한 번,
						 * 메일보안 추가 금액 계산할 떄 또 한번 호출될 것임.
						 * 즉, 해당 메소드를 호출할 때 연장 수량과 추가 수량이 동시에 존재하는 경우는 없다.
						 * defaultFeeItemModel이 있다는 건 기존의 기본서비스가 존재한다는 의미.
						 * */
						existingCount: item.defaultFeeItemModel?.usingCount || 0,
						addCount: item.defaultFeeItemModel ? 0 : 1,
						billingType: 'MONTHLY',
						channelItemType: channelItemType,
						itemCode: item.code,
						price: item.defaultFeePolicyModel.price,
					},
				},
			};

			const date = this.$store.getters.getDates;
			if (type === 'USERADD' || type === 'ADD') {
				orderInfoModel.baseInfoModel.orderType = 'ADD';
				orderInfoModel.baseChannelOrderInfoModel.applyDate = date.addStartDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.addEndDate;
			} else if (type === 'EXTENSION') {
				orderInfoModel.baseInfoModel.orderType = 'EXTENSION';
				orderInfoModel.baseChannelOrderInfoModel.applyDate = date.extensionStartDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.extensionEndDate;
			}

			const itemCalculateRequestModel = {
				orderInfoModel: orderInfoModel,
				channelItemType: channelItemType,
			};
			// TODO
			const response = await this.$store.dispatch('CALCULATE_ITEM_PRICE', itemCalculateRequestModel);

			const priceModel = response.data.priceModel;
			// 정가
			item['defaultFeeTotNormalPrice'] = priceModel.totalOriginPrice;
			// 할인 적용된 금액
			item['defaultFeeTotDiscountPrice'] = priceModel.totalFinalPrice;
			// 할인 금액
			item['defaultFeeTotDiscount'] = priceModel.totalDiscountPrice;

			return item;
		},
		// 사용자추가 원가, 할인가 setting
		/* item : channelModel
		 * userCnt: 사용자 수
		 * usePeriod : 사용기간
		 *             - 연장 : 개월 (usePeriod)
		 * 			   - 추가 : 개월 + 일 (usePeriod.remainMonth, usePeriod.remainDay)
		 * type : extension(연장) / add(신규추가) / userAdd(사용자추가)
		 * usingAddUserItem : 기존 사용자추가(add 때만 필요)
		 */
		async setAddUserDiscountMatrix(item, userCnt, usePeriod, type, usingAddUserItem) {
			// 기본 서비스에서 제공해주는 기본 사용자 수, 공유형은 0명
			let defaultCnt = parseInt(item.defaultFeePolicyModel.defaultUserNum);
			// 추가 사용자 수 : 요번에 추가한 인원 수 까지 포함한 총 인원 수
			let addUserCnt = userCnt;

			// 사용 기간
			item['usePeriod'] = usePeriod;
			// 사용 인원
			item['userCnt'] = userCnt;
			// 사용자 구간별 계산 정보 담을 리스트
			item['addUserDiscountMatrix'] = [];
			if (type == 'USERADD') {
				addUserCnt = userCnt;
			} else if (type == 'EXTENSION' || type == 'ADD') {
				addUserCnt = userCnt - defaultCnt;
			}
			item['addUserCnt'] = addUserCnt;

			// 사용자 수 구간별 계산
			let addUserIntervals = item.addUserIntervalPolicyModel.addUserIntervals;
			// 남은 유저 수?
			let remainUserCnt = 0;
			for (let idx in addUserIntervals) {
				let addUserInterval = addUserIntervals[idx]; // 구간
				if (defaultCnt < addUserInterval.max && addUserCnt > 0) {
					// 기본 데이터셋 설정
					const userInterval = {
						addUserIntervals: addUserIntervals[idx],
						interval: addUserInterval.min + '~' + addUserInterval.max,
						price: addUserInterval.price,
						code: addUserInterval.code,
						discountCnt: 0, // 구간 별 계산되어지는 사용자 수
						discountInfo: '',
						totPrice: 0,
						discountTotPrice: 0,
						originPrice: 0,
						discountPrice: 0,
						periodDiscountPrice: 0,
					};

					// 마이그레이션하며 모든 사용자가 0~50 구간으로 적용되어 예외 처리 필요 (remainUserCnt)
					let usingCount = remainUserCnt; // 기존 적용되었던 사용자추가 데이터
					const usingIdx = usingAddUserItem
						? usingAddUserItem.findIndex((item) => item.channelItemType == addUserInterval.channelItemType)
						: -1;
					if (type == 'USERADD' && usingIdx >= 0) {
						usingCount = usingCount + usingAddUserItem[usingIdx].usingCount;
						const minCount = addUserInterval.min == 0 ? defaultCnt : addUserInterval.min - 1;
						const maxCount = addUserInterval.max - minCount;

						if (usingCount > maxCount) {
							usingCount = maxCount >= 0 ? maxCount : 0;
							remainUserCnt = remainUserCnt + usingAddUserItem[usingIdx].usingCount - usingCount;
						}
					} else {
						const range =
							addUserInterval.min == 0
								? addUserInterval.max - addUserInterval.min
								: addUserInterval.max - addUserInterval.min + 1;
						if (range < usingCount) {
							remainUserCnt = usingCount - range;
							usingCount = range;
						}
					}
					if (defaultCnt > addUserInterval.min) {
						// 기본사용자 수가 포함된 구간 : 해당 구간 사용자 수 = 기본사용자 수 제외
						if (addUserInterval.max - defaultCnt - usingCount < addUserCnt) {
							userInterval.discountCnt = addUserInterval.max - defaultCnt - usingCount;
							addUserCnt -= addUserInterval.max - defaultCnt - usingCount;
						} else {
							userInterval.discountCnt = addUserCnt;
							addUserCnt -= addUserCnt;
						}
					} else if (addUserCnt <= addUserInterval.max - addUserInterval.min - usingCount) {
						// 추가 사용자 수가 해당 구간에 모두 포함될 경우
						userInterval.discountCnt = addUserCnt;
						addUserCnt -= addUserCnt;
					} else {
						if (addUserInterval.min == 0) {
							userInterval.discountCnt = addUserInterval.max - addUserInterval.min - usingCount;
						} else {
							userInterval.discountCnt = addUserInterval.max - addUserInterval.min + 1 - usingCount;
						}
						addUserCnt -= userInterval.discountCnt;
					}
					if (userInterval.discountCnt > 0) {
						item.addUserDiscountMatrix.push(userInterval);
					}
				}
			}

			const date = this.$store.getters.getDates;
			// 서버에 요청하여 금액 계산
			// TODO 공통화
			let orderInfoModel = {
				membershipModel: {
					id: this.$store.getters.getUsingMembership.id,
				},
				partnerSimpleModel: {
					partnerId: this.$store.getters.getUsingMembership.partnerSimpleModel.partnerId,
				},
				baseInfoModel: {
					orderType: 'ADD',
				},
				serviceType: item.serviceCategoryModel.serviceType,
				chargeType: item.chargeType,
				itemCount: userCnt,
				baseChannelOrderInfoModel: {
					applyDate: date.addStartDate,
					endDate: date.addEndDate,
					baseChannelModel: {
						code: item.code, // 서비스 아이템 코드
						id: item.id, // 서비스 id
					},
					addUserFeeOrderDetails: [],
				},
			};

			if (type === 'USERADD' || type === 'ADD') {
				orderInfoModel.baseInfoModel.orderType = 'ADD';
				// 신요금제 전환 대상이면 [추가연장 == 추가하면서 연장] 하므로 applyDate 는 연장기간과 동일
				const applyDate = this.$store.getters.isExtensionOrAddExtensionOrderWithConversionProduct
					? date.extensionStartDate
					: date.addStartDate;
				orderInfoModel.baseChannelOrderInfoModel.applyDate = applyDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.addEndDate;
			} else if (type === 'EXTENSION') {
				orderInfoModel.baseInfoModel.orderType = 'EXTENSION';
				orderInfoModel.baseChannelOrderInfoModel.applyDate = date.extensionStartDate;
				orderInfoModel.baseChannelOrderInfoModel.endDate = date.extensionEndDate;
			}

			let addUserFeeOrderDetails = [];
			for (let index in item.addUserDiscountMatrix) {
				let addUserMatrix = item.addUserDiscountMatrix[index];
				let addUserItemModel = {
					totalOrderCount: 0,
					existingCount: 0,
					addCount: 0,
					billingType: 'MONTHLY',
					channelItemType: addUserMatrix.addUserIntervals.channelItemType
						? addUserMatrix.addUserIntervals.channelItemType
						: 'ETC',
					itemCode: item.code,
					price: addUserMatrix.addUserIntervals.price,
				};
				if (orderInfoModel.baseInfoModel.orderType === 'EXTENSION') {
					addUserItemModel.existingCount = addUserMatrix.discountCnt;
				} else if (orderInfoModel.baseInfoModel.orderType === 'ADD') {
					addUserItemModel.addCount = addUserMatrix.discountCnt;
				}
				addUserItemModel.totalOrderCount = addUserItemModel.existingCount + addUserItemModel.addCount;
				addUserFeeOrderDetails.push(addUserItemModel);
			}
			orderInfoModel.baseChannelOrderInfoModel.addUserFeeOrderDetails = addUserFeeOrderDetails;

			const itemCalculateRequestModel = {
				orderInfoModel: orderInfoModel,
				channelItemType: 'USER',
			};
			// TODO
			const response = await this.$store.dispatch('CALCULATE_ITEM_PRICE', itemCalculateRequestModel);

			// 품목 별 할인 금액 및 할인정보 기입
			const addUserDiscountModels = response.data.itemPriceModels;
			for (let index in item.addUserDiscountMatrix) {
				let discountInfoModels = addUserDiscountModels[index].discountInfoModels;
				item.addUserDiscountMatrix[index].discountTotPrice = addUserDiscountModels[index].discountPrice;
				item.addUserDiscountMatrix[index].totPrice = addUserDiscountModels[index].finalPrice;
				item.addUserDiscountMatrix[index].originPrice = addUserDiscountModels[index].originPrice;
				let discountInfo = '';
				for (let innerIndex in discountInfoModels) {
					discountInfo += '<span>' + discountInfoModels[innerIndex].discountName;
					if (discountInfoModels[innerIndex].discountRate > 0) {
						discountInfo += '<span class="em">' + discountInfoModels[innerIndex].discountRate + '%</span>';
					}
					if (discountInfoModels[innerIndex].discountPrice > 0) {
						discountInfo += '<span class="em">' + discountInfoModels[innerIndex].discountPrice + '원</span>';
					}
					discountInfo += '</span><br>';
				}
				item.addUserDiscountMatrix[index].discountInfo = discountInfo;
			}

			item['addUserTotNormalPrice'] = response.data.priceModel.totalOriginPrice;
			item['addUserTotDiscountPrice'] = response.data.priceModel.totalFinalPrice;
			item['addUserTotDiscount'] = response.data.priceModel.totalDiscountPrice;

			return item;
		},
		async setDiscountSum(item) {
			if (item == null) {
				return;
			}
			if (item.periodPolicyModel == undefined || item.periodPolicyModel == null) {
				return;
			}

			if (
				item.periodPolicyModel.discountAmount !== undefined &&
				item.periodPolicyModel.discountAmount !== null &&
				item.periodPolicyModel.discountAmount.length > 0
			) {
				let discountAmountTot = 0;

				item.periodPolicyModel.discountAmount.forEach((val) => {
					discountAmountTot += parseInt(val);
				});

				item.periodPolicyModel['discountAmountTot'] = discountAmountTot;
			}
			return item;
		},
	},
};
