import { productClassifier } from '@/views/mixinProductClassifier';

export const serviceOperation = {
	mixins: [productClassifier],
	methods: {
		/*
		- 부가서비스 선택
		1) 선택
		   - 신규 추가
		2) 해지
		*/
		async selectAddonChannel(item) {
			if (item.usingCount > 0) {
				if (this.extensionPeriod === 0) {
					return;
				}
			}

			item.isSelected = !item.isSelected;

			// 부가서비스 선택 시
			if (item.isSelected) {
				if (this.isAccountAddonChannel(item)) {
					// 경리회계
					this.addonChannelList.reduce((previous, current) => {
						return previous.then(async () => {
							if (current.isSelected && this.isAccountAddonChannel(current) && current.code !== item.code) {
								await this.selectAddonChannel(current);
							}
						});
					}, Promise.resolve());
				}
				// 경리회계 선택이면, 다른 경리회계는 check 풀어야 함
				let usingAccountSystemCode = null; //사용중인 경리회계 서비스
				if (this.usingMembership.addonChannelInfoModels.length > 0) {
					const idx = this.usingMembership.addonChannelInfoModels.findIndex((addon) =>
						this.isAccountAddonChannel(addon.addonChannelModel),
					);
					if (idx > -1) {
						usingAccountSystemCode = this.usingMembership.addonChannelInfoModels[idx].addonChannelModel.code;
					}
				}

				// 선택
				await this.setChannelCalcInfo(item, 'ADDON');

				if (this.availableOptionItemsPerService.length > 0) {
					const idx = this.availableOptionItemsPerService.findIndex((obj) => obj.service.code == item.code);
					if (idx === -1) {
						// 선택한 부가서비스의 옵션상품 조회
						let optionData = await this.getAddonChannelOptionItemList(item.code, item);
						if (optionData && optionData.length > 0) {
							let optionPerService = {
								service: this.cloneObj(item),
								options: optionData,
							};
							optionPerService.service['usingCount'] = 1;
							await this.availableOptionItemsPerService.push(optionPerService);

							// 초기 setting값들 계산
							await optionData.forEach((obj) => {
								if (obj.items.length > 0) {
									obj.items.forEach((item) => {
										if (item.orderCount > 0) {
											this.setCntOptionItem(
												item,
												optionPerService.service,
												item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum,
											);
										}
									});
								}
							});
						}
					} else {
						const optionPerService = this.availableOptionItemsPerService[idx];
						let serviceType = '';
						let freeTrialPolicyModel = null;
						let isSelected = true;
						if (optionPerService.service.code.indexOf('ADDON-') > -1) {
							serviceType = 'ADDON';
							freeTrialPolicyModel = optionPerService.service.freeTrialPolicyModel;
							isSelected = await this.isSelectedAddon(optionPerService.service.code);
						} else {
							serviceType = 'BASE';
						}

						const service = {
							name: optionPerService.service.name,
							code: optionPerService.service.code,
							serviceCategoryModel: optionPerService.service.serviceCategoryModel,
							defaultFeePolicyModel: optionPerService.service.defaultFeePolicyModel,
							addUserIntervalPolicyModel: optionPerService.service.addUserIntervalPolicyModel,
							membershipFeePolicyModel: optionPerService.service.membershipFeePolicyModel,
							freeTrialPolicyModel: freeTrialPolicyModel,
							chargeType: optionPerService.service.chargeType,
							termsSectionModel: optionPerService.service.termsSectionModel,
							usingCount: 1,
							isSelected: isSelected,
						};
						await optionPerService.options.reduce((previousOption, option) => {
							return previousOption.then(async () => {
								await option.items.reduce((previousItem, item) => {
									return previousItem.then(async () => {
										await this.addUpdateOptionByCase(item, serviceType, service);
									});
								}, Promise.resolve());
							});
						}, Promise.resolve());
					}
				} else {
					// 선택한 부가서비스의 옵션상품 조회
					let optionData = await this.getAddonChannelOptionItemList(item.code, item);
					if (optionData && optionData.length > 0) {
						let optionPerService = {
							service: this.cloneObj(item),
							options: optionData,
						};
						optionPerService.service['usingCount'] = 1;
						await this.availableOptionItemsPerService.push(optionPerService);
						await optionData.forEach((obj) => {
							if (obj.items.length > 0) {
								obj.items.forEach((item) => {
									if (item.orderCount > 0) {
										this.setCntOptionItem(
											item,
											optionPerService.service,
											item.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum,
										);
									}
								});
							}
						});
					}
				}
			} else {
				if (item.usingCount > 0) {
					// 사용중인 부가서비스
					// 연장 연산
					if (this.extensionPeriod > 0) {
						// 연장일 경우 (연장 data만 빼기)
						let extensionAddonItem = this.cloneObj(item);
						await this.commitWithPayload('SERVICE', 'removeService', 'ADDON', extensionAddonItem, 'EXTENSION');
						// 옵션 연장도 빼기
						if (this.availableOptionItemsPerService.length > 0) {
							const idx = this.availableOptionItemsPerService.findIndex((obj) => obj.service.code == item.code);
							if (idx > -1) {
								const optionPerService = this.availableOptionItemsPerService[idx];
								let serviceType = '';
								let freeTrialPolicyModel = null;
								let isSelected = true;
								if (optionPerService.service.code.indexOf('ADDON-') > -1) {
									serviceType = 'ADDON';
									freeTrialPolicyModel = optionPerService.service.freeTrialPolicyModel;
									isSelected = await this.isSelectedAddon(optionPerService.service.code);
								} else {
									serviceType = 'BASE';
								}
								const service = {
									name: optionPerService.service.name,
									code: optionPerService.service.code,
									serviceCategoryModel: optionPerService.service.serviceCategoryModel,
									defaultFeePolicyModel: optionPerService.service.defaultFeePolicyModel,
									addUserIntervalPolicyModel: optionPerService.service.addUserIntervalPolicyModel,
									membershipFeePolicyModel: optionPerService.service.membershipFeePolicyModel,
									freeTrialPolicyModel: freeTrialPolicyModel,
									chargeType: optionPerService.service.chargeType,
									termsSectionModel: optionPerService.service.termsSectionModel,
									usingCount: 1,
									isSelected: isSelected,
								};
								await optionPerService.options.reduce((previousOption, option) => {
									return previousOption.then(async () => {
										await option.items.reduce((previousItem, item) => {
											return previousItem.then(async () => {
												await this.subtractOptionByCase(item, serviceType, service);
											});
										}, Promise.resolve());
									});
								}, Promise.resolve());
							}
						}
					} else {
						return;
					}
				} else {
					// 신규 추가한 부가서비스 (연장, 추가 data 다 빼기)
					let addAddonItem = this.cloneObj(item);
					let extensionAddonItem = this.cloneObj(item);
					await this.commitWithPayload('SERVICE', 'removeService', 'ADDON', addAddonItem, 'ADD');

					await this.commitWithPayload('SERVICE', 'removeService', 'ADDON', extensionAddonItem, 'EXTENSION');

					// 부가서비스 옵션 지우기
					const idx = this.availableOptionItemsPerService.findIndex((obj) => obj.service.code === item.code);
					if (idx > -1) {
						this.availableOptionItemsPerService.splice(idx, 1);
					}
				}
			}
			this.addonDefaultOptionSelect();
		},
		async setChannelCalcInfo(item, serviceType) {
			// 연장 연산
			if (this.extensionPeriod > 0) {
				// (연장)연장일 경우 : 연장기간 * 총 사용자 수 => 기본료, 사용자 추가 요금
				let extensionItem = this.cloneObj(item);

				extensionItem = await this.setDefaultFeeDiscountMatrix(
					extensionItem,
					this.extensionPeriod, // �������������장기간
					'EXTENSION',
				); // 기본료 계산

				extensionItem = await this.setAddUserDiscountMatrix(
					extensionItem,
					this.totUserCnt, // 총 사용자 수
					this.extensionPeriod, // 연장기간
					'EXTENSION',
				); // 사용자 추가 요금 계산

				if (
					this.originChargeType === 'FREE' &&
					this.currentChargeType === 'PAY' &&
					!this.usingMembership.baseChannelInfoModel.paidMembershipFeeDate
				) {
					// 무료 일반 -> 유료 전환이면서 유료 설치비 이력 없을 때
					extensionItem = await this.setMembershipFeeDiscountMatrix(extensionItem);
				}

				await this.commitWithPayload('SERVICE', 'setService', serviceType, extensionItem, 'EXTENSION');
			}
			// 추가 연산 (사용중/신규추가 분리)
			if (item.usingCount > 0) {
				// 사용중인 부가서비스
				if (this.totUserCnt > this.existingUserCnt) {
					// (추가)사용자 추가 : 잔여기간 * 추가 사용자 수(addUserCnt) => 사용자 추가 요금
					let addItem = this.cloneObj(item);
					let usingAddUserItem = null;
					if (serviceType === 'BASE') {
						usingAddUserItem = this.cloneObj(this.usingMembership.baseChannelInfoModel.addUserItemModels);
					} else if (serviceType === 'ADDON') {
						const idx = this.usingMembership.addonChannelInfoModels.findIndex(
							(obj) => obj.addonChannelModel.code === addItem.code,
						);
						usingAddUserItem = this.cloneObj(this.usingMembership.addonChannelInfoModels[idx].addUserItemModels);
					}
					addItem = await this.setAddUserDiscountMatrix(
						addItem,
						this.addUserCnt, // 추가 사용자 수
						this.remainDay, // 잔여기간
						'USERADD',
						usingAddUserItem,
					); // 사용자 추가 요금 계산

					await this.commitWithPayload('SERVICE', 'setService', serviceType, addItem, 'ADD');
				} else {
					return;
				}
			} else if (item.isSelected) {
				// 경리회계 전환인 경우, 추가 X
				if (await this.isConvertedAccount(item)) {
					return;
				}

				// 신규 추가한 부가서비스 (사용자 유지/추가 or 사용자 취소 분리)
				let addItem = this.cloneObj(item);
				if (this.totUserCnt === this.existingUserCnt || this.totUserCnt > this.existingUserCnt) {
					// (추가)신규 추가, 사용자 유지/추가 : (추가)잔여기간 * 총 사용자 수(사용자수 유지/추가) => 기본료, 사용자 추가 요금, 설치비

					addItem = await this.setDefaultFeeDiscountMatrix(
						addItem,
						this.remainDay, // 잔여기간
						'ADD',
					); // 기본료 계산

					addItem = await this.setAddUserDiscountMatrix(
						addItem,
						this.totUserCnt, // 추가 사용자 수
						this.remainDay, // 잔여기간
						'ADD',
					); // 사용자 추가 요금 계산

					addItem = await this.setMembershipFeeDiscountMatrix(addItem); // 설치비
				} else if (this.totUserCnt < this.existingUserCnt) {
					// (추가)신규 추가, 사용자 취소:(추가)잔여기간 * 기존 사용자 수(사용자수 취소) => 기본료, 사용자 추가 요금, 도입비
					addItem = await this.setDefaultFeeDiscountMatrix(
						addItem,
						this.remainDay, // 잔여기간
						'ADD',
					); // 기본료 계산

					addItem = await this.setAddUserDiscountMatrix(
						addItem,
						this.existingUserCnt, // 추가 사용자 수
						this.remainDay, // 잔여기간
						'ADD',
					); // 사용자 추가 요금 계산

					addItem = await this.setMembershipFeeDiscountMatrix(addItem); // 설치비
				} else {
					return;
				}
				await this.commitWithPayload('SERVICE', 'setService', serviceType, addItem, 'ADD');
			} else {
				return;
			}
		},
		async isSelectedAddon(serviceCode) {
			const data = this.addonChannelList.filter((obj) => obj.code === serviceCode);
			if (data.length) {
				return data[0].isSelected;
			} else {
				return false;
			}
		},
		//디폴트 세팅된 상품을 선택함.
		addonDefaultOptionSelect() {
			const addonChannel = this.availableOptionItemsPerService.filter((o) => o.service.code.indexOf('ADDON') > -1);
			addonChannel.forEach((addon) => {
				addon.options.forEach((option) => {
					option.items.forEach((item) => {
						if (item.orderCount > 0 || item.channelItemSalesSectionModel.defaultSelected) {
							this.upCntOptionItem(item, addon.service);
						}
					});
				});
			});
		},
		checkReduceData(type, orderCount, usingCount, extensionPeriod) {
			if (type === 'ADDON' && usingCount > 0 && extensionPeriod === 0) {
				this.openAlert('구매 취소가 불가능합니다.', '현재 사용중인 수 부가서비스는 구매 취소가 불가합니다.');
				return;
			}

			if (
				(type === 'USERCOUNT' || type === 'OPTION') &&
				usingCount > 0 &&
				extensionPeriod === 0 &&
				orderCount < usingCount
			) {
				this.openAlert('구매 취소가 불가능합니다.', '현재 사용중인 수 보다 적게는 구매 취소가 불가합니다.');
				return;
			}
		},
		async isConvertedAccount(service) {
			const serviceCode = service.code;
			// 전환된 회계서비스인지

			if (!this.isAccountAddonChannel(service)) {
				return false;
			}

			let usingAccountSystemCode = null; //사용중인 경리회계 서비스
			if (this.usingMembership.addonChannelInfoModels.length > 0) {
				const idx = this.usingMembership.addonChannelInfoModels.findIndex((addon) =>
					this.isAccountAddonChannel(addon.addonChannelModel),
				);
				if (idx > -1) {
					usingAccountSystemCode = this.usingMembership.addonChannelInfoModels[idx].addonChannelModel.code;
				}
			}

			if (usingAccountSystemCode && usingAccountSystemCode !== serviceCode) return true;

			return false;
		},
		//한번이라도 주문한 1회성 무료상품은 옵션대상에서 제외한다.
		filterOrderedOnceNoCharge(optionItems, addonChannelItem) {
			//첫부가서비스 선택시는 기존옵션들 모두 노출
			if (!addonChannelItem.onceItemHistoryCollection) {
				return optionItems;
			}

			//1회성 주문 히스토리 리스트에서 무료인 아이템 코드 추출
			const orderedOnceNoCharge = addonChannelItem.onceItemHistoryCollection.onceItemHistories
				.filter((o) => o.noCharge)
				.map((o) => {
					return o.itemCode;
				});
			// 현재  1회성이고 무료인 옵션중에 기존 신청했던 아이템은 제거한다.
			return optionItems.filter(
				(items) =>
					!(
						orderedOnceNoCharge.includes(items.code) &&
						items.channelItemSalesSectionModel.noCharge &&
						items.channelItemSalesSectionModel.billingType === 'ONCE'
					),
			);
		},
	},
};
