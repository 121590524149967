import { productClassifier } from '@/views/mixinProductClassifier';
export const setServices = {
	mixins: [productClassifier],
	methods: {
		// 기본서비스에 귀속된 부가서비스 조회
		async getAddonChannelList(param) {
			// 각 부가서비스 항목 별 선택 여부
			try {
				let { data } = await this.$store.dispatch('GET_ADDONCHANNELLIST', param); // 부가서비스 리스트 조회
				if (data == undefined || data == null || Object.keys(data).length == 0) {
					return;
				}

				// 마이그레이션 여부에 따라 삭제하면 될것 같다.
				let usingAccountSystemCode = null; //사용중인 경리회계 서비스
				if (this.usingMembership.addonChannelInfoModels.length > 0) {
					const idx = this.usingMembership.addonChannelInfoModels.findIndex((addon) =>
						this.isAccountAddonChannel(addon.addonChannelModel),
					);
					if (idx > -1) {
						usingAccountSystemCode = this.usingMembership.addonChannelInfoModels[idx].addonChannelModel.code;
					}
				}

				this.addonChannelList = [];
				await data.reduce((previous, current) => {
					return previous.then(async () => {
						current['usingCount'] = 0;
						current['isSelected'] = false;
						current['visible'] = true;
						// 사용중인 경리회계 서비스랑 check
						if (usingAccountSystemCode) {
							if (this.isAccountAddonChannel(current) && usingAccountSystemCode !== current.code) {
								current.visible = false;
							}
						}

						let saveFlag = true;
						// 사용중인 부가서비스 check
						if (this.usingMembership.addonChannelInfoModels.length > 0) {
							const idx = this.usingMembership.addonChannelInfoModels.findIndex(
								(row) => row.addonChannelModel.code === current.code,
							);
							if (idx > -1) {
								if (
									this.usingMembership.addonChannelInfoModels[idx].addonChannelModel.freeTrialPolicyModel &&
									this.usingMembership.addonChannelInfoModels[idx].addonChannelModel.freeTrialPolicyModel.usePolicy &&
									this.usingMembership.addonChannelInfoModels[idx].freeTrialStatus === 'USING'
								) {
									// 무료체험이면서 사용중이면 저장 X
									saveFlag = false;
								} else {
									current.isSelected = true;
									current.usingCount = 1;
									current['defaultFeeItemModel'] = this.usingMembership.addonChannelInfoModels[idx].defaultFeeItemModel;

									current['addUserItemModels'] = this.usingMembership.addonChannelInfoModels[idx].addUserItemModels;

									/*
									if (this.isChangeOrder) {
										// 부가서비스추가
										await this.commitWithPayload(
											'SERVICE',
											'setService',
											'ADDON',
											current,
											'ADD',
										);
									}*/
								}
							}
						}
						if (saveFlag) {
							this.addonChannelList.push(current);
						}
					});
				}, Promise.resolve());
			} catch (error) {
				console.log(error);
			}
		},
		// 기본서비스 선택 시 귀속된 기본서비스 옵션상품들 조회
		async getBaseChannelOptionItemList(code) {
			// 각 항목 선택 여부
			try {
				const param = {
					code: code,
					orderType: this.extensionPeriod === 0 ? 'ADD' : 'EXTEND',
				};
				let data = [];
				let addExtensionOptions = await this.$store.dispatch('GET_BASECHANNEL_ADD_EXTENSION_OPTIONITEM_LIST', param); // 기본서비스 옵션
				data = addExtensionOptions.data;
				// 기존 사용중(신규때 신청한) 기본서비스 옵션상품들

				if (this.usingMembership.baseChannelInfoModel.usingChannelItemModels.length > 0) {
					await this.usingMembership.baseChannelInfoModel.usingChannelItemModels.reduce((previous, item) => {
						return previous.then(async () => {
							let temp = await this.$store.dispatch('GET_BASECHANNEL_OPTIONITEM_BY_CODE', item.itemCode);
							const idx = data.findIndex((obj) => obj.code === item.itemCode);
							if (idx === -1) {
								temp.data['addBlock'] = true; // 단종된 상품 추가 block
								data.push(temp.data);
							}
						});
					}, Promise.resolve());
				}
				if (!data || Object.keys(data).length === 0) {
					return;
				}
				const seperatedOptions = await this.seperateByItemGroup(data);
				await seperatedOptions.forEach((optionGroup) => {
					optionGroup.items.forEach((key) => {
						// 사용중 mapping
						if (key.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity) {
							key['orderCount'] = key.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum;
						} else {
							// 사용중 mapping
							key['orderCount'] = 0;
						}
						key['usingCount'] = 0;
						if (this.usingMembership.baseChannelInfoModel.usingChannelItemModels.length > 0) {
							const idx = this.usingMembership.baseChannelInfoModel.usingChannelItemModels.findIndex(
								(item) => item.itemCode === key.code,
							);
							if (idx > -1) {
								const usingItem = this.usingMembership.baseChannelInfoModel.usingChannelItemModels[idx];
								key.usingCount = usingItem.usingCount;
								// 가상도메인일 경우, 도메인 값 넣어주기
								if (key.itemType === 'VIRTUAL_DOMAIN') {
									const usingDomains = this.usingMembership.representativeGroupware?.virtualDomain;
									if (usingDomains) {
										usingDomains.forEach((domain) => {
											key.domainText.push({ text: domain, isExisting: true });
										});
									}
									//가상도메인을 삭제한경우 주문은 있지만 도메인text는 지워짐.
									//방어코드 추가 관련issue - DOS-1512
									if (usingDomains && usingDomains.length < parseInt(key.usingCount)) {
										for (let i = 0; i < parseInt(key.usingCount) - usingDomains.length; i++) {
											key.domainText.push({
												text: '제거된 가상도메인',
												isExisting: true,
											});
										}
									}
								}

								key['membershipItemModels'] = {
									discountPrice: this.usingMembership.baseChannelInfoModel.usingChannelItemModels[idx].discountPrice,
									discountRate: this.usingMembership.baseChannelInfoModel.usingChannelItemModels[idx].discountRate,
								};
								key.orderCount = key.usingCount;
							}
						}
						key['gapCount'] = 0;
					});
				});

				return seperatedOptions;
			} catch (error) {
				console.log(error);
			}
		},
		// 부가서비스 선택 시, 선택 부가서비스에 귀속된 옵션 조회
		async getAddonChannelOptionItemList(param, addonChannelItem) {
			// addonChannelItem : this.usingMembership.baseChannelInfoModel
			try {
				let { data } = await this.$store.dispatch('GET_ADDONCHANNEL_EXTENSION_OPTIONITEM_LIST', param); // 부가서비스 옵션 리스트
				if (!data || Object.keys(data).length === 0) {
					return;
				}
				const seperatedOptions = await this.seperateByItemGroup(this.filterOrderedOnceNoCharge(data, addonChannelItem));
				seperatedOptions.forEach((optionGroup) => {
					optionGroup.items.forEach((key) => {
						if (key.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity) {
							key['orderCount'] = key.channelItemSalesSectionModel.channelItemSalesPolicyModel.minimum;
						} else {
							// 사용중 mapping
							key['orderCount'] = 0;
						}
						key['usingCount'] = 0;
						if (addonChannelItem) {
							if (addonChannelItem.usingChannelItemModels && addonChannelItem.usingChannelItemModels.length > 0) {
								const idx = addonChannelItem.usingChannelItemModels.findIndex((item) => item.itemCode === key.code);
								if (idx > -1) {
									key.usingCount = addonChannelItem.usingChannelItemModels[idx].usingCount;
									key.orderCount = key.usingCount;
									key['membershipItemModels'] = {
										discountPrice: addonChannelItem.usingChannelItemModels[idx].discountPrice,
										discountRate: addonChannelItem.usingChannelItemModels[idx].discountRate,
									};
								}
							}
						}
						key['gapCount'] = 0;
					});
				});
				return seperatedOptions;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
