<template>
	<div class="wrap_row_card">
		<span class="tit"
			>{{ option.groupModel.name }}<a v-if="option.groupModel.selectionType == 'RADIO'"> (택 1)</a></span
		>
		<div class="wrap_sub">
			<table class="com_tb_metadata tb_sub_option">
				<tbody>
					<tr v-for="item in option.items" :key="item.code">
						<td class="tit">
							<span class="ic_set ic_depth"></span>
							<span class="txt">{{ item.displaySectionModel.displayName }}</span>
							<span v-if="item.displaySectionModel.useTooltip" class="com_help">
								<span class="ic_set ic_info">
									<span class="tool_tip">
										{{ item.displaySectionModel.tooltipDesc }}
										<i class="tail_left"></i
									></span>
								</span>
							</span>
							<span v-if="item.displaySectionModel.useIcon" class="com_tag_badge">
								<span v-if="item.displaySectionModel.iconType == 'HOT'" class="txt">HOT</span>
								<span v-if="item.displaySectionModel.iconType == 'RECOMMENDATION'" class="txt">추천</span>
							</span>
							<span class="com_tag tag_s" v-if="item.usingCount > 0">
								<span class="txt">사용중</span>
							</span>
						</td>
						<td
							class="data"
							:colspan="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity ? '2' : '0'"
						>
							<template v-if="item.channelItemSalesSectionModel.priceType === 'ESTIMATE'">
								<span class="price">견적가</span>
							</template>
							<template v-else>
								<span v-if="item.channelItemSalesSectionModel.billingType == 'MONTHLY'" class="price"
									>{{ item.channelItemSalesSectionModel.price | currency }}원 /{{
										item.channelItemSalesSectionModel.channelItemSalesPolicyModel.unit
									}}개</span
								>
								<span v-if="item.channelItemSalesSectionModel.billingType == 'ONCE'" class="price"
									>{{ item.channelItemSalesSectionModel.price | currency }}원</span
								>
							</template>

							<span class="price billing_type">
								{{ item.channelItemSalesSectionModel.billingType | billingTypeDesc }}
							</span>
							<span
								class="com_number"
								v-if="item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity"
							>
								<button class="minus" v-on:click="downCntOptionItem(item, optionPerService.service)">
									<span class="ic_set ic_minus"></span>
								</button>
								<input class="ipt_normal" type="number" name="quantity" v-model="item.orderCount" />
								<button class="plus" v-on:click="upCntOptionItem(item, optionPerService.service)">
									<span class="ic_set ic_plus"></span>
								</button>
							</span>
						</td>
						<td class="check" v-if="!item.channelItemSalesSectionModel.channelItemSalesPolicyModel.useOrderQuantity">
							<div class="com_ck bk">
								<label
									><input
										type="checkbox"
										:disabled="isItemDisable(item)"
										:checked="isItemChecked(item)"
										v-on:click="
											item.orderCount == 0
												? upCntOptionItem(item, optionPerService.service)
												: checkDownCntOptionItem(item, optionPerService.service)
										" /><span class="checkmark"></span
								></label>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
export default {
	mixins: [option],
	props: ['option', 'optionPerService'],
};
</script>

<style scoped>
.billing_type {
	margin-left: -10px;
}
</style>
