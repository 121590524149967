export const option = {
	methods: {
		openAlert(title, content) {
			this.$emit('openAlert', title, content);
		},
		checkDownCntOptionItem(item, dependonService, type, index, domainExisting) {
			this.$emit('checkDownCntOptionItem', item, dependonService, type, index, domainExisting);
		},
		upCntOptionItem(item, dependonService) {
			this.$emit('upCntOptionItem', item, dependonService);
		},
		downCntOptionItem(item, dependonService, type, index) {
			this.$emit('downCntOptionItem', item, dependonService, type, index);
		},
		//상품관리에서 디폴트 체크되어있으면  disabled처리
		isItemDisable(item) {
			return item.channelItemSalesSectionModel.defaultSelected;
		},
		//한번 주문한 상품이거나 상품관리에서 디폴트 체크된 상품이면 항상 체크되어야함.
		isItemChecked(item) {
			if (item.orderCount > 0 || item.channelItemSalesSectionModel.defaultSelected) {
				return true;
			}
			return false;
		},
	},
	filters: {
		billingTypeDesc(value) {
			let billingTypeDesc = '';
			switch (value) {
				case 'ONCE':
					billingTypeDesc = '/1회';
					break;
				case 'MONTHLY':
					billingTypeDesc = '(월)';
					break;
				case 'ESTIMATE':
					billingTypeDesc = '(견적가)';
					break;
			}
			return billingTypeDesc;
		},
	},
};
