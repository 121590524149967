<template>
	<div class="container" v-if="availableOptionItemsPerService.length">
		<h2>옵션</h2>
		<template v-for="optionPerService in availableOptionItemsPerService">
			<label class="form_tit" :key="'label_' + optionPerService.service.code">
				<span class="txt_medium">{{ optionPerService.service.name }}</span>
			</label>
			<div class="form_data" :key="optionPerService.service.code">
				<div class="com_card_normal card_svc_option">
					<template v-for="(option, index) in optionPerService.options">
						<!--@가상 도메인-->
						<template v-if="getGroup(option) === '가상도메인'">
							<virtual-domain
								v-for="item in option.items"
								:optionPerService="optionPerService"
								:item="item"
								:key="option.groupModel.code + item.code + index"
								@openAlert="openAlert"
								@upCntOptionItem="upCntOptionItem"
								@checkDownCntOptionItem="checkDownCntOptionItem"
							></virtual-domain>
						</template>

						<!--@용량 추가-->
						<add-volume
							v-if="getGroup(option) === '용량추가'"
							:optionPerService="optionPerService"
							:option="option"
							:key="'volume' + index"
							@checkDownCntOptionItem="checkDownCntOptionItem"
							@upCntOptionItem="upCntOptionItem"
						></add-volume>
						<etc-group
							v-if="getGroup(option) === 'ETC'"
							:optionPerService="optionPerService"
							:option="option"
							:key="index"
							@downCntOptionItem="downCntOptionItem"
							@upCntOptionItem="upCntOptionItem"
							@checkDownCntOptionItem="checkDownCntOptionItem"
						>
						</etc-group>
						<!--@ 그루핑 되지 않은 기타 옵션-->
						<template v-if="!getGroup(option)">
							<not-groupping
								v-for="item in option.items"
								:key="item.code"
								:optionPerService="optionPerService"
								:item="item"
								:totUserCnt="totUserCnt"
								:unitCount="unitCount"
								@upCntOptionItem="upCntOptionItem"
								@checkDownCntOptionItem="checkDownCntOptionItem"
							></not-groupping>
						</template>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { option } from '@/components/apply/user/store/addExtension/optionItem/MixinsOption.js';
import VirtualDomain from '@/components/apply/user/store/addExtension/optionItem/VirtualDomain.vue';
import AddVolume from '@/components/apply/user/store/addExtension/optionItem/AddVolume.vue';
import EtcGroup from '@/components/apply/user/store/addExtension/optionItem/EtcGroup.vue';
import NotGroupping from '@/components/apply/user/store/addExtension/optionItem/NotGroupping.vue';
export default {
	mixins: [option],
	props: ['value', 'totUserCnt', 'unitCount'],
	components: { VirtualDomain, AddVolume, EtcGroup, NotGroupping },
	data() {
		return {
			availableOptionItemsPerService: this.value,
		};
	},
	watch: {
		value() {
			this.availableOptionItemsPerService = this.value;
		},
		availableOptionItemsPerService: {
			deep: true,
			handler() {
				this.$emit('input', this.availableOptionItemsPerService);
			},
		},
	},
	methods: {
		getGroup(optionItem) {
			return optionItem.groupModel && optionItem.groupModel.isGroupped
				? optionItem.groupModel.name.indexOf('가상') > -1
					? '가상도메인'
					: optionItem.groupModel.name.indexOf('용량') > -1
					? '용량추가'
					: 'ETC'
				: false;
		},
	},
};
</script>

<style></style>
