<template>
	<div class="content_body">
		<membership-info
			v-if="originMembership"
			:fold="fold"
			@changeFold="changeFold"
			:originMembership="originMembership"
			:remainDay="remainDay"
			:membershipStatus="membershipStatus"
			:originChargeType="originChargeType"
		></membership-info>
		<using-addon-status v-if="!fold" :usingMembership="originMembership"> </using-addon-status>
		<total-user-count
			v-model="totUserCnt"
			:usingMembership="usingMembership"
			:existingUserCnt="existingUserCnt"
			:addUserCnt="addUserCnt"
			:extensionPeriod="extensionPeriod"
			:currentChargeType="currentChargeType"
			:checkUserCount="checkUserCount"
		></total-user-count>
		<extension-period
			v-model="extensionPeriod"
			:usingMembership="usingMembership"
			:membershipStatus="membershipStatus"
			:originChargeType="originChargeType"
			:currentChargeType="currentChargeType"
		></extension-period>
		<addon-service
			v-model="addonChannelList"
			:extensionPeriod="extensionPeriod"
			@selectAddonChannel="selectAddonChannel"
		></addon-service>
		<option-items-per-service
			v-model="availableOptionItemsPerService"
			:totUserCnt="totUserCnt"
			:unitCount="unitCount"
			@openAlert="openAlert"
			@checkDownCntOptionItem="checkDownCntOptionItem"
			@upCntOptionItem="upCntOptionItem"
			@downCntOptionItem="downCntOptionItem"
		></option-items-per-service>
	</div>
</template>
<script>
import MembershipInfo from '@/components/apply/user/store/addExtension/MembershipInfo';
import UsingAddonStatus from '@/components/user/UsingAddonStatus.vue';
import ExtensionPeriod from '@/components/apply/user/store/addExtension/ExtensionPeriod';
import TotalUserCount from '@/components/apply/user/store/addExtension/TotalUserCount';
import AddonService from '@/components/apply/user/store/addExtension/AddonService';
import OptionItemsPerService from '@/components/apply/user/store/addExtension/optionItem/OptionItemsPerService';
import { product } from '@/views/mixinsProduct.js';
import { setServices } from '@/views/order/mixinsSetServices.js';
import { usingMembership } from '@/views/order/mixinsUsingMembership.js';
import { extensionPeriodChange } from '@/views/order/mixinsExtensionPeriodChange.js';
import { addUserCountChange } from '@/views/order/mixinsAddUserCountChange.js';
import { calculate } from '@/views/order/mixinsCalculate.js';
import { convertMembership } from '@/views/order/mixinsConvertMembership.js';
import { serviceOperation } from '@/views/order/mixinsServiceOperation.js';
import { optionOperation } from '@/views/order/mixinsOptionOperation.js';
import { promotion } from '@/views/common/promotion.js';

export default {
	mixins: [
		product,
		setServices,
		usingMembership,
		extensionPeriodChange,
		addUserCountChange,
		calculate,
		convertMembership,
		serviceOperation,
		optionOperation,
		promotion,
	],
	components: {
		MembershipInfo,
		UsingAddonStatus,
		ExtensionPeriod,
		TotalUserCount,
		AddonService,
		OptionItemsPerService,
	},
	data() {
		return {
			fold: true,
			waitExtensionPeriod: false,
			waitUserCount: false,
			preCurrentChargeType: '',
			membershipStatus: 'USING',
			isPreMemberhip: false, // 구상품 사용여부
			extensionPeriod: 0,
			originMembership: null, // 사용중이던 멤버십
			usingMembership: {}, // 멤버십
			usingBaseChannel: [],
			usingAddonChannel: [],
			baseChannelList: [], // 기본서비스 (API)
			addonChannelList: [], // 기본서비스 (API)
			availableOptionItemsPerService: [], //서비스 별 선택 가능한 옵션 상품
			basicItemSelected: null,
			existingUserCnt: 0, // 기존
			totUserCnt: 10, // 기존 + 신규
			userCnt: 0,
			usePeriod: 0,
			termsList: [],
			stepBtnInfo: {
				next: '다음',
				nextLink: '/apply/step2',
			},
			slidemenuTop: 0,
			modalFlag: false,
			remainDay: {},
			originChargeType: '', // 기존 사용중이던 멤버십의 과금 유형
		};
	},
	computed: {
		isChangeOrder() {
			return this.$store.getters.getFixedOrderType === 'CHANGE' ? true : false;
		},
		currentChargeType() {
			return this.waitExtensionPeriod || this.waitUserCount ? this.preCurrentChargeType : this.getCurrentChargeType();
		},
		addUserCnt() {
			return parseInt(this.totUserCnt) - parseInt(this.existingUserCnt);
		},
	},
	watch: {
		currentChargeType() {
			this.$store.commit('setNewMembershipChargeType', this.currentChargeType);
			this.setFixedOrderType();
		},
	},
	async created() {
		await this.setUsingMembershipInfo();
		await this.checkBaseChannelIsConvertable(this.usingMembership.baseChannelInfoModel.baseChannelModel.id);
		await this.getTodayPromotions();
		this.originChargeType = this.usingMembership.membershipChargeType; // 초기 로딩 시 originChargeType 세팅
		this.preCurrentChargeType = this.originChargeType;
		this.$store.commit('setOriginMembershipChargeType', this.originChargeType);
		this.$store.commit('setEnableNextOrder', this.usingMembership.enableNextOrder);
		this.remainDay = await this.getRemainDays(); // 남은 사용일 수
		this.$store.commit('setRemainDay', this.remainDay);
		this.usingMembership['userCnt'] = this.usingMembership.totalUserCnt;
		this.existingUserCnt = this.usingMembership.totalUserCnt; // 기존 사용자 수
		this.totUserCnt = this.usingMembership.totalUserCnt; // 처음 로딩 시, 사용자 수는 기존과 동일
		this.setExtensionPeriod();
		await this.setUsingBaseInfo(); // 현재 페이지에 사용중 멤버십 set하고, 멤버십에 딸려있는 부가서비스 & 옵션 가져오기
		this.$store.commit('setAddExtensionUserCnt', this.totUserCnt);
		this.originMembership = this.usingMembership;
	},
	async mounted() {
		this.$store.commit('clearApplyServiceInfo');
		this.$store.commit('clearExtensionOrAddExtensionOrderWithConversionProduct');
		this.addonChannelList = [];
		window.addEventListener('scroll', this.detectWindowScrollY);
	},
	beforeDestory() {
		window.removeEventListener('scroll', this.detectWindowScrollY);
	},
	methods: {
		changeFold(value) {
			this.fold = value;
		},
		getCurrentChargeType() {
			let currentChargeType = this.usingMembership.membershipChargeType;
			const monthlyOptions = [];
			const services = this.cloneObj(this.$store.getters.getBaseChannel);
			services.push(...this.cloneObj(this.$store.getters.getAddonChannel));
			if (services.length && currentChargeType !== this.$constants.chargeType.PAY) {
				if (this.originChargeType === this.$constants.chargeType.FREE) {
					if (services.length) {
						services.forEach((o) => {
							monthlyOptions.push(
								...o.option.filter(
									(o) =>
										o.channelItemSalesSectionModel.billingType === 'MONTHLY' &&
										o.channelItemSalesSectionModel.price > 0,
								),
							);
						});
					}
					if (monthlyOptions.length) {
						currentChargeType = this.$constants.chargeType.FREE_MONTHLY;
					}
				} else if (this.originChargeType === this.$constants.chargeType.FREE_MONTHLY) {
					if (services.length) {
						services.forEach((o) => {
							monthlyOptions.push(
								...o.option.filter(
									(o) =>
										o.channelItemSalesSectionModel.billingType === 'MONTHLY' &&
										o.channelItemSalesSectionModel.price > 0 &&
										o.extension.length,
								),
							);
						});
					}
					if (this.extensionPeriod > 0 && !monthlyOptions.length) {
						currentChargeType = this.$constants.chargeType.FREE;
					}
				}
			}

			this.setPreCurrentChargeType(currentChargeType);
			return currentChargeType;
		},
		setPreCurrentChargeType(chargeType) {
			this.preCurrentChargeType = chargeType;
		},
		setFixedOrderType() {
			let orderType = '';
			orderType =
				this.originChargeType === this.$constants.chargeType.FREE_MONTHLY &&
				this.currentChargeType === this.$constants.chargeType.PAY
					? 'CHANGE'
					: orderType;
			orderType =
				(this.originChargeType === this.$constants.chargeType.FREE &&
					this.currentChargeType === this.$constants.chargeType.FREE_MONTHLY) |
				(this.originChargeType === this.$constants.chargeType.FREE &&
					this.currentChargeType === this.$constants.chargeType.PAY)
					? 'EXTENSION'
					: orderType;

			if (orderType === 'EXTENSION') {
				// 추가 데이터 다 지우기
				this.$store.commit('clearAddDatas');
			}
			this.$store.commit('setFixedOrderType', orderType);
		},
		setExtensionPeriod() {
			if (this.currentChargeType === this.$constants.chargeType.FREE) {
				this.extensionPeriod = 0;
				return;
			}
			if (this.$route.path == '/order/addExtension/autoExtension') {
				this.extensionPeriod = 1;
			}
			if (this.membershipStatus !== 'USING') {
				this.extensionPeriod = 12;
			}
		},
		openAlert(title, content) {
			this.$ms.dialog.alert(title, content);
		},
		cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		getFormateDate(val) {
			const date = new Date(val);
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
		async getRemainDays() {
			// api 호출
			let remainMonth = 0;
			let remainDay = 0;

			if (this.originChargeType !== this.$constants.chargeType.FREE && this.membershipStatus == 'USING') {
				const today = new Date();
				const year = new String(today.getFullYear());
				let month = new String(today.getMonth() + 1);
				let day = new String(today.getDate());
				if (month.length == 1) {
					month = '0' + month;
				}
				if (day.length == 1) {
					day = '0' + day;
				}
				const todayStr = year + '-' + month + '-' + day;
				const { data } = await this.$store.dispatch('GETREMAINDAYS', {
					membershipId: this.usingMembership.id,
					startDate: todayStr,
				});

				remainMonth = data.remainMonth;
				remainDay = data.remainDay;
			}
			return {
				remainMonth,
				remainDay,
			};
		},
		getUsePeriod(fromVal, toVal) {
			const fromDate = new Date(fromVal);
			const toDate = new Date(toVal);
			const usingDay = (toDate.getTime() - fromDate.getTime()) / 1000 / 60 / 60 / 24 / 30;
			return Math.round(usingDay);
		},

		/* type : SERVICE/ OPTION
		 * option : type == OPTION 경우에만
		 */
		async commitWithPayload(type, methodName, serviceType, service, operationType, option) {
			let payload = null;
			if (type == 'SERVICE') {
				service = await this.setDiscountSum(service);
				payload = {
					serviceType: serviceType,
					operationType: operationType,
					service: service,
				};
			} else if (type == 'OPTION') {
				payload = {
					serviceType: serviceType,
					operationType: operationType,
					service: service,
					option: option,
				};
			}
			await this.$store.commit(methodName, payload);
		},
		priceCutting(aprice, stype, n) {
			// 금액, 타입, 절삭금액 단위
			// 원단위처리(R:반올림, C:올림, F:버림)
			var remove_price = 0;
			stype = stype ? stype : 'R';
			remove_price = aprice / n;

			if (stype == 'F') {
				remove_price = Math.floor(remove_price);
			} else if (stype == 'R') {
				remove_price = Math.round(remove_price);
			} else if (stype == 'C') {
				remove_price = Math.ceil(remove_price);
			}

			remove_price = remove_price * n;
			return remove_price;
		},
	},
};
</script>
<style scoped>
.com_layer_normal {
	z-index: 9999;
}
.user_container {
	overflow-x: unset;
}
</style>
